import React from 'react'
import Base from 'components/Base'

function shouldUpdateScroll({ routerProps }) {
  const { hash } = routerProps.location

  if (!hash) {
    return true
  }

  const element = document.querySelector(hash)

  if (!element) {
    return true
  }

  return false
}

function wrapRootElement({ element }) {
  return (
    <Base>
      {element}
    </Base>
  )
}

export {
  shouldUpdateScroll,
  wrapRootElement,
}